// NOTE: Vue3 recommends to not use mixins anymore
// https://vuejs.org/api/options-composition.html#mixins
// refactor when we can
// used in ViewpointCollection.vue


export default {
  methods: {
    getHeaders(type, item) {
      return [
        {
          name: 'X-Session',
          value: this.$store.getters['user/session']
        },
        { name: 'X-Process', value: type },
        { name: 'item', value: item }
      ];
    }
  }
};
