<template>
  <!-- mostly a duplicate of ViewpointsIndex -->
  <div>
    <q-dialog
      v-model="toggleForm"
      persistent
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <viewpoint-collection-form
        @reload="reload"
        :record="current"
        :action="action"
      />
    </q-dialog>

    <!-- sub navigation of Narrative -->
    <section id="buttonbar" v-if="isAdmin">
      <div class="row q-mx-auto">
        <div class="col q-py-md">
          <a class="subnavlink" @click="addViewpoint">
            <i class="fa fa-plus-large q-pr-sm" aria-hidden="true" />
            Add a Viewpoint Collection
          </a>
        </div>
      </div>
    </section>

    <hi-search
      v-if="items.length"
      v-model="searchFor"
      placeholder="Find your Viewpoints"
    />

    <section id="card-holder">
      <div class="row q-mx-auto justify-start items-start">
        <!--q-btn
          class="q-mb-md"
          v-if="isAdmin"
          icon="add"
          label="Add Viewpoint Collection"
          color="primary"
          @click="addViewpoint"
        /-->
      </div>

      <div class="row q-mx-auto justify-start items-start">
        <div class="row q-gutter-md">
          <span :key="vp.uuid" v-for="vp in filteredItems" :id="vp.uuid">
            <card
              :uuid="vp.uuid"
              :title="vp.title"
              :image="vp.icon"
              :url="`/my/viewpoints/${vp.slug}/`"
              type="Viewpoint Collection"
              :csstype="vp.is_visible ? 'ViewpointCollection' : 'private'"
              :btn-edit-modal="user === vp.owner"
              @showEdit="showEdit(vp)"
              :btn-delete="user === vp.owner"
              @deleteCard="remove(vp.uuid)"
              :btn-publish="!vp.is_visible"
              :btn-un-publish="vp.is_visible"
              @publish="publish(vp)"
              @unpublish="publish(vp)"
              >{{ vp.introduction }}
            </card>
          </span>
        </div>

        <span v-if="items.length === 0">
          <h3>
            <i class="fa fa-exclamation-circle" aria-hidden="true" /> No
            viewpoints yet
          </h3>
          <br />
          <q-btn
            class="q-mb-lg"
            icon="fa fa-plus"
            label="Create a new Viewpoint"
            color="primary"
            no-caps
            @click="$router.push('/narratives/new')"
          />
        </span>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { API } from "@/tic";
import ViewpointCollectionForm from "@/forms/ViewpointCollection";
import Card from "../tags/Card";

export default {
  name: "ViewpointsIndex",
  components: { ViewpointCollectionForm, Card },
  data() {
    return {
      searchFor: "",
      items: [],
      current: null,
      action: "Add",
      toggleForm: false,
    };
  },

  computed: {
    ...mapGetters("user", { isAdmin: "isAdmin", user: "uuid" }),
    filteredItems() {
      if (this.searchFor) {
        return this.items.filter(
          (e) =>
            e.title.includes(this.searchFor) ||
            e.introduction.includes(this.searchFor)
        );
      } else {
        return this.items;
      }
    },
  },

  mounted() {
    this.reload();
  },

  methods: {
    remove(uuid) {
      let vm = this;
      console.log("xx: ", uuid);
      API.post("/viewpoints/delete/collection", {
        collection: uuid,
        user: vm.$store.getters["user/uuid"],
      }).then(() => {
        vm.reload();
      });
    },

    publish(vp) {
      let vm = this;
      API.post("/viewpoints/toggle/visibility", {
        collection: vp.uuid,
        session: vm.$store.getters["user/session"],
      }).then(() => {
        vm.reload();
      });
    },

    showEdit(e) {
      this.current = e;
      this.toggleForm = true;
      this.action = "Edit";
    },

    addViewpoint() {
      this.current = {};
      this.action = "Add";
      this.toggleForm = true;
    },

    reload(toggle = false) {
      //this.$q.notify({ message: 'RELOADED', position: 'center' });
      return API.post("/viewpoints/my", {
        user: this.$store.getters["user/uuid"],
      })
        .then((r) => {
          this.items = [...r.data.data];
        })
        .then(() => {
          this.toggleForm = toggle;
        });
    },
  },
};
</script>
