<template>
  <!--
  view all Viewpoint Collections
  /viewpoints/
-->
  <div>
    <q-card flat style="width: 560px; max-width: 60vw;">
      <q-bar
        ><b>{{ action }} a Viewpoint Collection</b>
        <q-space />
        <q-btn v-close-popup dense round flat icon="close" />
      </q-bar>
      <q-card-section>
        <div v-if="vp.icon && vp.icon.path">
          <q-img cover style="height: 180px; max-width: 240px" class="" :src="vp.iconic" />
          <q-btn
            @click="deleteImage(vp.uuid)"
            round
            size="md"
            icon="fa fa-trash-alt"
            title="Delete icon"
            class="float-right"
            color="black"
          />
        </div>

        <!-- dont show upload image on adding as no uuid for the VP is known yet -->
        <span v-if="action != 'Add'">
          <q-btn
            size="md"
            no-caps
            @click="$refs.uploader_vp.pickFiles()"
            label="Upload iconic image"
            icon="fa fa-upload"
            class="q-my-sm"
            color="hardroze"
          />
          <q-uploader
            v-show="false"
            ref="uploader_vp"
            url="/api/upload"
            :headers="getHeaders('iconicimage', vp.uuid)"
            :multiple="false"
            accept=".jpg, image/*"
            label="Add Iconic Image"
            @uploaded="processUpload"
            auto-upload
          />
        </span>
      </q-card-section>
      <q-card-section>
        <q-form class="q-gutter-md q-pa-md">
          <q-input filled v-model="vp.title" label="Title" />
          <q-input
            filled
            type="textarea"
            v-model="vp.introduction"
            label="Short introduction text on Card"
            min-height="5rem"
          />
            <quill-editor
              ref="editor"
              v-model="vp.text"
              placeholder="Text about this Collection"
              :toolbar-options="[
                [{ 'header': [ 3, 4, 5, false] }],
                ['bold', 'italic', 'underline'],
                ['blockquote',{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['image'] // add's image support
              ]"
            />

          <!-- <q-input
            filled
            type="textarea"
            v-model="vp.text"
            label="Text about this Collection"
            min-height="5rem"
          /> -->
        </q-form>
      </q-card-section>
      <q-card-actions class="q-pa-xl" align="center">
        <q-btn no-caps :disabled="false" v-close-popup label="Cancel" />
        <q-btn
          no-caps
          color="green"
          :disabled="isEmpty"
          label="Save"
          @click="save"
        />
      </q-card-actions>
    </q-card>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import getHeaders from '../mixins/getHeaders.mixin.js';
import QuillEditor from '@/components/QuillEditor';

import { API } from '@/tic';

export default {
  name: 'ViewpointCollectionForm',
  components: { QuillEditor },
  mixins: [getHeaders],
  props: {
    record: {
      type: Object,
      default: () => {}
    },

    action: {
      type: String,
      default: ''
    }
  },
  mounted() {
    if (this.record) {
      this.vp = { ...this.record };
    }
  },
  data() {
    return {
      toggleViewpoint: false,
      vp: {
        title: '',
        introduction: '',
        iconic: ''
      }
    };
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
    isEmpty: function() {
      return _.isEmpty(Object.values(this.vp).filter(Boolean));
    }
  },
  methods: {
    deleteImage() {
      API.post('/viewpoints/deleteIcon', { uuid: this.vp.uuid }).then(() => {
        this.vp.iconic = '';
        this.$emit('reload', true);
      });
    },

    processUpload(u) {
      let vm = this;
      // get uuid+url from upload reply and add them to this partner
      let reply = JSON.parse(u.xhr.response);
      // this.partner[reply.item].url = reply.url;
      // this.partner[reply.item].uuid = reply.uuid;
      // Vue.set(this.vp.icon, reply.item, {
      //    url: reply.url,
      //    uuid: reply.uuid
      // });

      //this.vp.iconic = reply.url;
      // Vue.set(this.vp.iconic, reply.url)
      vm.vp.iconic = reply.url;
      this.$emit('reload', true);
    },

    save() {
      API.post(`/viewpoints/${this.action.toLowerCase()}`, {
        session: this.$store.getters['user/session'],
        data: this.vp
      }).then(() => {
        let msg = this.action === 'Add' ? 'created' : 'updated';
        this.$q.notify({ message: `Collection ${msg}` });
        this.$emit('reload');
      });
    }
  }
};
</script>
